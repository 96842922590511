<template>
  <div class="cx-search-box" :class="is_mobile ? 'm-cx-search-box' : 'pc-cx-search-box'">
    <div class="cx-search-list">
      <div class="cx-search-input">
        <input class="cx-s-input" v-model="search_value" typ="text" @keypress.enter="goTo" />
        <img class="cx-s-icon" :src="search_icon" @click="goTo" />
      </div>
      <div class="cx-search-tip">{{$t('search.text')}} "{{ search_value }}" {{$t('search.result')}} {{ search_list.length }}</div>
      <div class="cx-search-item" v-for="(item,i) in search_list" :key="i">
        <router-link :to="'/recruitment/detail?id=' + item.id" v-if="item.search_type=='recruit'">
          <div class="cx-s-title">{{ item[$t('language.shot_name')+'_title'] }}</div>
          <div class="cx-s-desc">{{ item[$t('language.shot_name')+'_operating_duty'] }}</div>
        </router-link>

        <div v-if="item.search_type=='history'">
          <div class="cx-s-title">{{item['content_'+$t('language.shot_name')]}}</div>
          <div class="cx-s-desc">{{item['content_'+$t('language.shot_name')]}}</div>
        </div>

        <div v-if="item.search_type=='article'">
          <div class="cx-s-title">{{ item[$t('language.shot_name')+'_title'] }}</div>
          <div class="cx-s-desc">{{item[$t('language.shot_name')+'_desc']}}</div>
        </div>
        <!-- <router-link :to="'/news/info/'+item.id" v-if="item.search_type=='article'">
          <div class="cx-s-title">{{ item[$t('language.shot_name')+'_title'] }}</div>
          <div class="cx-s-desc">{{item[$t('language.shot_name')+'_desc']}}</div>
        </router-link> -->
      </div>
    </div>
  </div>
</template>
<script>
export default{
  name: "Search",
  props: {
  },
  data() {
    return {
      search_value:'域控制器',
      search_icon:require('../assets/seach-icon.png'),
      search_list:[]
    };
  },
  methods:{
    goTo(){
      this.$router.push('/search/'+this.search_value)
      this.seartch();
    },
    seartch(){
      if(this.search_value){
        this.api.search({
          keyword:this.search_value
        }).then(res=>{
          if(res.code==200){
            this.search_list=res.data;
          }
        })
      }
    }
  },
  components: {},
  mounted() {
    this.search_value=this.$route.params.value;
    this.seartch();
  }
}
</script>
<style lang="less" scoped>
.cx-search-box{
  width: 1455px;
  min-height: 960px;
  margin:120px auto 0px;
  text-align:left;
}
.cx-search-tip{
  font-size: 21px;
  color:#8d8d8d;
}
.cx-s-title{
  color:#3392ff;
  font-size: 29px;
  margin-top:30px;
}
.cx-search-item{
  border-bottom:#9a9a9a solid 1px;
  overflow: hidden;
}
.cx-s-desc{
  color: #fff;
  font-size: 21px;
  line-height: 39px;
  padding:20px 0px;
}
.cx-search-input{
  position: relative;
  margin-top:50px;
  width: 520px;
  height:70px;
  border:#ffffff solid 1px;
  border-radius: 10px;
  margin-bottom:40px;
}
.cx-s-input{
  font-size: 33px;
  color:#ffffff;
  margin-left:30px;
  margin-top:10px;
  width: 410px;
}
.cx-s-icon{
  position: absolute;
  width:40px;
  right:20px;
  top:12px;
}
.m-cx-search-box{
  width: 690px;
  margin:0 auto;
}
.cx-s-load{
  color: #aaa;
  font-size: 28px;
  border: #aaa solid 1px;
  width: 180px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  user-select:none;
  cursor: pointer;
  margin:50px auto 0px;
}
</style>